.HistoryAdminCreateComponent {
  margin: 10px auto;
  margin-bottom: 40px;
  max-width: 1200px;
  background: #f6f6f7;
  padding: 30px 40px;
  h3, h4 {
    margin: 20px 0;
  }
  hr {
    border: 0.5px solid #e0dfdf;
    margin: 20px 0;
  }
  .HistoryAdminCreateComponent__Input {
    display: flex;
    justify-content: center;
    align-content: flex-end;
    .HistoryAdminCreateComponent__Input__Content {
      width: 460px;
    }
    .HistoryAdminCreateComponent__Input__Month {
      width: 70px;
    }
    .CreateButton {
      margin-top: 40px;
      width: 100px;
    }
  }
  .MuiFormControl-root {
    margin: 40px 20px 10px 20px;
  }
  .MuiSelect-select.MuiSelect-select {
    width: 100px;
  }
}