.HistoryAdminEditComponent {
  .HistoryAdminEditComponent__Icon {
    cursor: pointer;
  }
  svg {
    margin: 10px;
    justify-content: end;
  }
  
  hr {
    border: 0.5px solid #e0dfdf;
    margin-bottom: 10px;
  }
  .MuiSelect-select.MuiSelect-select {
    width: 100px;
  }

}
.Modal {
  align-items: 'center';
  .Modal__Inside {
    padding: 20px 0;
    margin: 20vh auto 0;
    background-color: white;
    width: 500px;
    .Modal__Inside__Icon {
      text-align: end;
      margin-right: 25px;
      svg {
        cursor: pointer;
      }
    }
    .Modal__Inside__Title {
      text-align: center;
      margin-bottom: 40px;
    }
    .Modal__Inside__Input {
      padding: 0 50px;
      margin: 20px;
      text-align: 'left';
      display: block;
    }
    .Modal__Inside__Button {
      text-align: end;
      margin-top: 50px;
    }
  }
  .MuiFormControl-root {
    display: block;
    margin: 20px 0;
  }
}