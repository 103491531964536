.DownloadManualsComponent {
  margin-bottom: 100px;
  .DccMain {
    margin: 0 auto;
    padding-top: 80px;
    max-width: 1300px;
    display: flex;
    justify-content: center;
    .DownloadTableTitle {
      display: flex;
      justify-content: space-between;
    }
    .DownloadTable__Admin {
      svg {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  
  .DownloadTable {
    margin: 0 40px;
    width: 550px;
  }
  
  .TableTitle {
    font-family: 'Poppins Bold';
    color: #0f4c81;
    margin-bottom: 20px;
  }
  
  .TableHead {
    background-color: rgb(243, 243, 243);
  }
  
  .DescIcon {
    margin-left: 5px;
  }
}
