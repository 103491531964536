.DownloadTableComponent {
    margin: 0 40px;
    width: 700px;
    .DownloadTableTitle {
      display: flex;
      justify-content: space-between;
    }
    .TableTitle {
      font-family: 'Poppins Bold';
      color: #0f4c81;
      margin-bottom: 20px;
    }
    
    .TableHead {
      background-color: rgb(243, 243, 243);
    }
    
    .DescIcon {
      margin-left: 5px;
    }
    .MuiTableCell-alignRight {
      text-align: right;
      width: 500px;
    }
    .MuiTableCell-body {
        width: 200px;
    }
  }