@import '../../common.scss';

.SideNavMain {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 15px;
  top: 20px;
}

.CategoriesTag {
  font-family: 'NanumSquare Extra Bold';
  width: 100%;
  padding-left: 8px;
  padding-bottom: 25px;
  font-size: 1.5rem;
  margin-bottom:5px;
  color: #333333;
  font-weight: bolder;
}

.SideNavLink {
  text-decoration: none;
}

.SideNavButton {
  width: 100%;
  span {
    justify-content: start;
  }
}

.SideNavText {
  font-family: 'NanumSquare Regular';
  font-size: 1rem;
  color:rgba(36, 36, 36, 0.788)
}

.SelectSideNavText {
  font-family: 'NanumSquare Regular';
  font-size: 1.05rem;
  color: $navbar-color;
}