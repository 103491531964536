.ProductPage {
  // sm: 600, md: 960, lg; 1280, xl:1920
  width: 100%;
  margin: 30px 0px 40px;
  display: flex;
}

.ProductPage__SideNavDiv {
  max-width: 200px;
  margin-inline: 80px 60px;
}

.ProductContent{
  width: 1280px;
  margin: 0 auto;
}


.LoadingIndicator {
  width: 720px;
  margin: 0 auto;
  text-align: center;
}