.DownloadAdminEditComponent {
  margin: 50px 0;
  svg {
      margin-right: 10px;
      cursor: pointer;
  }

  .DownloadAdminFiles__TitleBox {
    margin: 0 auto;
  }

  .DownloadAdminFiles__FileBox {
    margin: 5px auto;
    margin-bottom: 25px;
    padding: 13px;
    border: 3px solid rgb(67, 160, 71);
    border-radius: 10px;
  }

  .DownloadAdminFiles__ButtonBox {
    margin-top: 10px;

  }

  .DownloadAdminFiles__Label {
    display: flex;
  }
  .MuiButton-containedPrimary {
    color: #fff;
    background-color: rgb(67, 160, 71);
    &:hover {
      background-color: rgb(67, 160, 71);
    }
  }
}