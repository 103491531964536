.ProductMain {
  padding: 0px 10px;
  img {
    // width: 100%;
    width: 320px;
    height: 133px;
  }
}

.ProductCategories {
  margin: 0px 0px 50px 0px;
  font-family: 'Poppins Bold';
  font-size: 1.5rem;
  color: #2d2e2e;
  padding: 0px 30px;
  // box-shadow: 0 4px 3px -3px rgb(155, 155, 155);
}

.ProductCagtoryName {
  margin-bottom: 10px;
}

.ProductsCardBoard {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.ProductCard {
  max-width: 300px;
  margin-right: 80px;
  margin-bottom: 39px;
}

.ProductLink {
  text-decoration: none;
}

.ProductPaper {
  padding: 10px;
  width: 340px;
  height: 257px; 
}

.ProductImageWrap {
  height: 80%;
  display: flex;
  align-items: center;
}

.ProductName {
  font-family: 'NanumSquare Extra Bold';
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 10px;
  // border-bottom: 1px solid;
}

.productCategoryHr {
  color: black;
  opacity: 0.7 ;
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #00000000, #818078, transparent);
  background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), rgb(129, 128, 120), rgba(50, 50, 50, 0.1));
}

.PhotoBlock {
  .__react_modal_image__caption {
    color: black;
  }
}