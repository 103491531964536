@import '../common.scss';

.IntroducePage__LinkBox {
  padding: 12px 200px;
  height: 52px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}

.IntroducePage__Link {
  font-family: 'NanumSquare Bold';
  text-decoration: none;
  color:rgba(36, 36, 36, 0.788)
}

.IntroducePage__Link__Select {
  font-family: 'NanumSquare Bold';
  text-decoration: none;
  color: $navbar-color;
}
