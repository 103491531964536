@import '../common.scss';

.NavigationBar {
  margin: 0;
  font-size: 20px;
  background-color: white;
}

.NavigationBar__UpperBox {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavigationBar__Logo {
  img {
    width: 140px;
    padding-top: 5px;
  }
}

.NavigationBar__LinkBox {
  width: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.NavigationBar__Link {
  font-family: 'NanumSquare Extra Bold';
  text-decoration: none;
  margin: 20px 0;
  color: #333333;
}

.NavigationBar__Link:hover {
  color: $navbar-color;
}

.NavigationBar__SearchIcon {
  color: #333333;
  cursor: pointer;
  // padding-right: 20px;
}

.NavigationBar__LowerBox {
  display: flex;
  justify-content: flex-end;
  padding: 0 50px 10px;

  &--Disable {
    display: none;
  }
}

.NavigationBar__Search {
  border-radius: 5px;
  padding: 5px 0 5px 10px;
  width: 300px;
  height: 30px;
  font-size: 16px;
}

.NavigationBar__AdminButtons {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button, a {
    margin-left: 10px;
  }
}
