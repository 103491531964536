.NoticeForm__Form {
  width: 100%;
  height: 900px;
}

.NoticeForm__Header {
  width: 800px;
  margin: 50px auto;
  text-align: center;
}

.NoticeForm__TitleBox {
  width: 800px;
  margin: 10px auto;

  > input {
    margin: 10px 0;
    padding: 2px 5px;
    width: 100%;
    height: 36px;
    font-size: 18px;
  }
}

.NoticeForm__ContentBox {
  width: 800px;
  margin: 10px auto;
}

.NoticeForm__ContentEditorWrapper {
  margin: 10px 0;
  padding: 2px 0 2px;
}

.NoticeForm__ButtonBox {
  width: 800px;
  margin: 10px auto;

  > button {
    width: 120px;
    height: 50px;
  }
}
