.ProductDetailWrap {
  padding-left:20px;
  h2 {
    margin-bottom: 12px;
    font-family: 'Poppins';
    color: #555555;
  }
}

.adminBtn {
  margin-left: auto;
}

.ProductNameText {
  font-family: 'Poppins';
  color: rgb(47, 46, 56);
}

.ProductImage {
  width: 380px;
  height: 158px;
}

.UpperContent {
  display: flex;
  margin-bottom: 77px;
}

.PhotoBlockWrap{
  display: flex;
  align-items: center;
}

.PhotoBlock {
  width: 400px;
  height: auto;
  margin-right: 50px;
}

ul {
  list-style:none;
  padding-left: 6px;
}

li {
  margin-bottom: 8px;
}

.ProductInfo {
  margin-bottom: 77px;
}

.OverviewTextDiv {
  padding-left: 6px;
  width: 90%;
}

.OverviewText {
  text-align:justify ;
  line-height: 1.6rem;
}

.ProductLoadingIndicatorDiv {
  width: 720px;
  margin: 0 auto;
  text-align: center;
}

.RightContent {
  line-height: 1.6rem;
  flex-wrap: wrap;
}

.detailPrintOut {
  margin-top: -100px;
  padding: 30px 0 50px 20px;
  text-align:justify ;
  line-height: 1.6rem;
  h2 {
    margin-top: 60px;
    margin-bottom: 12px;
    font-family: 'Poppins';
    color: #555555;
  }
  p {
    width: 90%;
  }
}

.btnWrapper {
  .adminBtn {
    margin-bottom: 10px;
  }
  hr {
    margin-bottom: 10px;
  }
}

.__react_modal_image__modal_content img, .__react_modal_image__modal_content svg {
  width: 1440px;
  height: 600px;
}