@font-face {
  font-family: 'NanumSquare Light';
	src: url('../src/static/fonts/NanumSquare_acL.ttf');
}

@font-face {
  font-family: 'NanumSquare Regular';
  src: url('../src/static/fonts/NanumSquare_acR.ttf');
}

@font-face {
  font-family: 'NanumSquare Bold';
	src: url('../src/static/fonts/NanumSquare_acB.ttf');
}

@font-face {
  font-family: 'NanumSquare Extra Bold';
	src: url('../src/static/fonts/NanumSquare_acEB.ttf');
}

@font-face {
	font-family: 'NanumGothic';
	src: url('../src/static/fonts/NanumGothic.ttf');
}

@font-face {
	font-family: 'NanumGothic Bold';
	src: url('../src/static/fonts/NanumGothicBold.ttf');
}

@font-face {
	font-family: 'Poppins';
	src: url('../src/static/fonts/Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Poppins Bold';
	src: url('../src/static/fonts/Poppins-Bold.ttf');
}

@font-face {
	font-family: 'Poppins Extra Bold';
	src: url('../src/static/fonts/Poppins-ExtraBold.ttf');
}

@font-face {
	font-family: 'Poppins Black';
	src: url('../src/static/fonts/Poppins-Black.ttf');
}
