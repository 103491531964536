.NoticeDetail__Box {
  margin: 70px 25%;
}

.NoticeDetail__TitleBox {
  margin: 10px 0;
}

.NoticeDetail__Title {
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.NoticeDetail__CreatedAt, .NoticeDetail__Creator, .NoticeDetail__Views {
  font-size: 12px;
  color: gray;
  margin-bottom: 10px;
  padding-bottom: 5px;
} 

.NoticeDetail__metaDataBox {
  display: flex;
  justify-content: space-between;
}

.NoticeDetail__ContentBox {
  margin-top: 20px;
  padding: 20px 10px;
  border-bottom: 2px solid #e0e0e0;
  min-height: 30vh;
  word-break: keep-all;
  > div {
    > p {
      line-height: 1.5em;
    }
  }
}

.NoticeDetail__ButtonBox {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  > button {
    width: 110px;
  }
}

.NoticeDetail__AdminButtonBox {
  > button {
    width: 50px;
    margin-right: 5px;
  }
}

.NoticeDetail__UpdateButton {
  color: white !important; 
  background-color: #303030 !important;
}


