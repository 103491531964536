@import '../../common.scss';

.ADS {
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
  margin-bottom: 200px;

  .clicked {
    background-color: #cdd3d9;
    color: $navbar-color;
    font-weight: bolder;
  }  
  .ADS__Helptext {
    display: block;
    height: 40px;
    font-size: 14px;
    margin-top: 10px;
  }
}

.ADS__Header {
  margin: 40px 0;
  text-align: center;
}

.ADS__ApplicationBox {
  min-width: 900px;
  margin: 0;
  border-radius: 10px;
  padding: 40px;
  background-color: #f2f4f5;
}

.ADS__Application {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cbd1d6;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  min-width: 250px;
}

.ADS__ShowInput {
  width: 100%;
}

.ADS__TITLE {
  font-size: 20px;
  margin: 20px 0;
  margin-top: 40px;
}

.ADS__Input {
  margin-left: 30px;
  height: 50px;
  align-items: center;
  .ADS__Input__Title {
    margin-top: 20px;
  }
  .MuiOutlinedInput-multiline {
    margin: 20px 0;
    width: 700px;
  }
  .MuiButton-contained {
    margin-top: 28px;
    margin-left: 40px;
    padding: 5px;
  }
}
.ADS_Final_Button {
  display: flex;
  justify-content: flex-end;
}

.ADS__Input .MuiButton-contained {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 20px;
  padding: 5px;
}

.Admin__Button {
  margin-bottom: 10px;
}

.AdminCategory__InputTab hr {
  margin: 50px 0;
  color: black;
  opacity: 0.7;
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #00000000, #818078, transparent);
  background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), #818078, rgba(50, 50, 50, 0.1));
}

.AdminApplication__FileForm {
  margin-top: 20px;
  button {
    margin-right: 20px;
  }
}