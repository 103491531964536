.BusinessPartnerComponent {
  padding: 70px 0;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1000px;
  hr {
    margin: 50px 0;
    color: black;
    opacity: 0.7;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, #00000000, #818078, transparent);
    background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), #818078, rgba(50, 50, 50, 0.1));
  }
}

.BusinessPartnerComponent__Block {
  display: flex;
  h2 {
    width: 200px;
    margin: 20px 0;
  }
  h4 {
    padding-top: 25px;
    color: #333333;
  }
}

.BusinessPartnerComponent__Text {
  text-align: center;
  margin-bottom: 15px;
}

.BusinessPartnerComponent__Image {
  width: 145px;
  height: 70px;
  display: block;
  margin: 5px auto;
  object-fit: contain;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #cacaca;
}