.AdminProducts {
  min-height: 80vh;
  margin: 0 0 100px;
  hr {
    margin: 50px 0;
    color: black;
    opacity: 0.7;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, #00000000, #818078, transparent);
    background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), #818078, rgba(50, 50, 50, 0.1));
  }
}
.AdminCateogry__CreateProductTab {
  width: 100%;
  height: auto;
  padding: 10px 0;
}

.AdminCategory__InnerTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.AdminCategory__TabTitle {
  font-size: 20px;
  margin: 20px 0;
  
}

.AdminCategory__Button {
  display: block;
  margin: 5px 0 !important;
  display: flex;
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
}

// INPUT TAB HERE
.AdminCategory__InputTab {
  margin: 10px 0 0;
  .MuiFormControl-root {
    width: 100%;
  }
}

.AdminCategory__InputTitle {
  margin: 10px 0;
}

.AdminCategory__Input {
  width: 80%;
  height: 30px;
  font-size: 22px;
  padding: 10px;
}

.AdminCategory__UpperArea {
  display: flex;
  justify-content: space-between;
  margin: 10px 0
}

.AdminCategory__InputButton {
  display: flex;
  margin: 20px 10px 0 0 !important;
  justify-content: flex-end;
}
