.OverviewComponent {
  padding: 70px 0;
  margin: 0 auto 60px;
  max-width: 900px;
  flex-grow: 1;
  line-height: 2;
}

.OverviewComponent__Text {
  margin: 20px 0;
}

.OverviewComponent__SignContainer {
  font-family: 'NanumSquare Extra Bold';
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.OverviewComponent__CompanyTitle {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > span:nth-child(1) {
    display: inline-block;
    width: 100px;
  }

  > span:nth-child(2) {
    display: inline-block;
    width: 80px;
  }
}
