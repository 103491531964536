.Footer {
  margin: 0;
  padding:50px 15%;
  background-color: #333333;
  color: #dddddd;
  width: 100%;
  height: 280px;
  
  // Footer position
  position: absolute;
  bottom: 0;
  left: 0;
  
  hr {
    border: 0.5px solid #555454;
  }
}

.Footer__LinkBox {
  display: flex;
  font-weight: bolder;
  font-size: 15px;
  justify-content: space-around;
  padding: 15px;
}

.Footer__Link {
  text-decoration: none;
  color: #dddddd;
}

.Footer__Info {
  padding: 20px;
  margin-top: 20px;
  font-size: 13px;
  color: rgba(214, 212, 212, 0.534);
  h4 {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
  }
}