.BusinessAreaComponent {
  padding: 70px 0;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 900px;
  h2 {
    padding-bottom: 50px;
  }
  img {
    text-align: center;
    width: 850px;
  }
}