*, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NanumGothic', 'NanumSquare Regular', 'Poppins';
}

body {
  height: 100vh;
  border: 0;
  min-height: 100%;
}

#root {
  position: relative;
  min-width: 1300px;
  min-height: 100%;
  /* this padding is for Footer */
  padding-bottom: 280px;
}

.MuiButton-root {
  font-family: 'NanumGothic', 'NanumSquare Regular', 'Poppins';
}
