@import '../../common.scss';

.HomeIntroduceBlock {
  width: 920px;
  height: 400px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;

  .HomeIntroduceBlock__Image {
    width: 600px;
  }

  .HomeIntroduceBlock__Content {
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    a {
      text-decoration: none;
    }
    .Title {
      font-size: 25px;
      font-weight: bold;
      .Title__Blue {
        color: $navbar-color;
      }
    }
  
    .Content {
      font-size: 18px;
      font-weight: normal;
      color: dimgray;
      word-break: break-all;
      div {
        margin: 5px 0;
      }
    }
  }
}
