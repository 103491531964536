.HistoryComponent {
  padding: 70px 0;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 1500px;

  h2 {
    color: black;
  }
  .HistoryComponentBlock{
    padding: 20px 25px;
    h4 {
      color: #333333;
    }
    p {
      line-height: 2;
    }
  }
}
