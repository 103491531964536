@import '../common.scss';

.AdminPage__Admin {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 900px;
}

.AdminPage__LoginBox {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminPage__Username,
.AdminPage__Pwd {
  margin:10px;
  padding: 5px;
  height: 60px;
  width: 400px;
  font-size: 20px;
}

.AdminPage__LoginButton {
  margin: 20px 0;
  height: 50px;
  width: 380px;
  font-size: 20px;
}

.AdminPage__LoginTitle {
  margin: 30px;
  text-align: center;
}

// TAB
.AdminPage__TabBox {
  width: 100%;
  padding: 12px 200px;
  height: 52px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .1)
}

.AdminPage__Tab {
  width: 200px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.AdminPage__Tab--selected {
  > span {
    color: $navbar-color;
    font-weight: bolder;
  }
}