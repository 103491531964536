.SearchResultComponent {
  .SearchResultComponent__Link {
    text-decoration: none;
    color: black;
    width: 100%;
  }

  .SearchResultComponent__Block {
    cursor: pointer;
    padding: 15px 0;
    .SearchResultComponent__Block__Title {
      padding: 12px 0;
    }
    .SearchResultComponent__Block__Content {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* 라인수 */
      -webkit-box-orient: vertical;
      line-height: 1.9em;
      color: #333;
    }
    .SearchResultComponent__Block__Date {
      padding: 5px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
