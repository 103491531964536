.DownloadAdminCreateComponent {
  margin: 50px auto;
  margin-bottom: 40px;
  max-width: 1200px;
  background: #f6f6f7;
  padding: 30px 40px;
  h3, h4 {
    margin: 20px 0;
  }
  h5 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  hr {
    border: 0.5px solid #e0dfdf;
    margin: 40px 0;
  }
  svg {
    margin-right: 10px;
    cursor: pointer;
  }
  .DownloadAdminCreateComponent__Input {
    display: flex;
    justify-content: center;
  }
  
  .DownloadAdminCreateComponent__Input__FileBox {
    margin-top: 10px;
    p {
      margin: 10px 0;
      color: rgba(0, 0, 0, 0.54);
      font-size: small;
    }
  }
  
  .DownloadAdminCreateComponent__Input__Label {
    display: flex;
  }
  .DownloadAdminCreateComponent__CreateButton {
    margin-top: 45px;
    margin-left: 20px;
  }
  .MuiFormControl-root {
    margin: 40px 20px 10px 20px;
  }
  .MuiSelect-select.MuiSelect-select {
    width: 200px;
  }
  .MuiFormControl-fullWidth {
    width: 300px;
  }
}
