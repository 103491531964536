.ContactComponent {
  padding: 70px 0;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 900px;
  h4, h3 {
    padding: 25px 0;
  }
  span {
    font-weight: bolder;
    color: #333333;
  }
  p {
    padding: 8px 0;
  }
}

.SubwayBlock {
  img {
    height: 65px;
    padding: 10px 25px 10px 0;
  }
  h3 {
    padding: 30px 0 8px 0;
  }
}

.SubwayInfo {
  display: flex;
  padding: 5px 0;
}

.SubwayInfo__Text {
  margin-top: 5px;
  p {
    padding: 5px 0;
  }

}