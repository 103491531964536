.DownloadCataloguesComponent {
  margin-bottom: 100px;
  .DccMain {
    margin: 0 auto;
    padding-top: 80px;
    max-width: 1500px;
    display: flex;
    justify-content: center;
   
  }
}