@import '../../common.scss';

.AdminProducts__ProductsBoxTitle {
  text-align: center;
  margin: 40px 0;
  .MuiButton-contained {
    margin-left: 10px;
  }
}
.AdminProducts__ProductsBox {
  display: flex;
  min-width: 900px;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f2f4f5;
  margin: 0;
  width: 600px;
  border-radius: 10px;
  padding: 30px;
}

.AdminProducts__UpperCategoryBox {}

.AdminProducts__UpperCategoryTitle {
  text-align: center;
  margin: 20px 0;
}

.AdminProducts__UpperCategory {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  width: 250px;
  text-align: center;
  border: 1px solid #cbd1d6;
  border-radius: 10px;
  background-color: white;
}

.AdminProducts__CategoryBox {}

.AdminProducts__CategoryTitle {
  text-align: center;
  margin: 20px 0;
}

.AdminProducts__Category {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  width: 300px;
  text-align: center;
  border: 1px solid #cbd1d6;
  border-radius: 10px;
  background-color: white;
}

.AdminProducts__ProductBox {
  min-width: 200px;
}

.AdminProducts__ProductTitle {
  text-align: center;
  margin: 20px 0;
}

.AdminProducts__ProductsBoxSubTitle {
  display: block;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;
}

.AdminProducts__Product {
  padding: 10px;
  cursor: pointer;
  min-width: 250px;
  text-align: center;
  border: 1px solid #cbd1d6;
  border-radius: 10px;
  margin: 5px 0;
  background-color: white;
}

.AdminProducts__SelectedProducts {
  width: 100%;
  .AdminProducts__SelectedProduct > div {
    width: 100%;
  }
}

.AdminProducts__SelectedProduct {
  width: 100%;
  > div {
    margin: 10px 0;
  }
  > input {
    width: 100%;
    height: auto;
    word-break: break-word;
    font-size: 15px;
  }

  > textarea {
    width: 100%;
    height: 120px;
    font-size: 15px;
  }
}

.AdminProducts__Editor {
  max-width: 900px;
  > div {
    margin: 10px 0;
  }
}

.AdminProducts__SubmitButtonBox {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.AdminProducts__ResetButton {
  display: flex;
  justify-content: flex-end;
}

.AdminProducts__SubmitButton {
  margin-left: 30px;
}

.AdminProducts__CreateProductButtonBox {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
    .MuiSvgIcon-root {
    margin-right: 10px;
  }
}

.AdminProducts__CreateProductButton {
  min-width: 400px;
  max-width: 600px;
  height: 40px;
}

.clicked {
  background-color: #cdd3d9;
  color: $navbar-color;
  font-weight: bolder;
}

.AdminProducts__FileForm {
  width: 100%;
  margin: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
