.ProductEditorCommpoent__Wrapper {
  padding-right: 50px;
  > hr {
    margin-top: 50px;
    color: black;
    opacity: 0.7 ;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, #00000000, #818078, transparent);
    background-image: linear-gradient(to right, rgba(50, 50, 50, 0.1), rgb(129, 128, 120), rgba(50, 50, 50, 0.1));
  }
}

.Editor__ProductName {
  > input {
    margin: 10px 0;
    padding: 2px 5px;
    width: 100%;
    height: 36px;
    font-size: 18px;
  }
}

.EditorWrapper {
  margin-top: 30px;
  > h2 {
    font-family: "NanumSquare Bold";
    margin-bottom: 10px;
  }
}

.btnDiv{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .btnCancel{
    margin-right:10px;
  }
}

.preview {
  margin-top: 30px;
  h1 {
    margin-bottom: 20px;
  }
}

.previewName {
  margin-top: 30px;
  h3 {
    color: rgb(99, 99, 99);
  }
}

.overview {
  background-color: rgb(250, 250, 250);
  padding: 15px;
  padding: 20px 0 50px 30px;
  h2 {
    margin-block: 30px 15px;
    font-family: 'Poppins';
    color: rgb(99, 99, 99);
  }
}

.inappCnntWrapper {
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  .exclmIcon {
    font-size: 150px;
    color: #F65058;
  }
}