.ImageForm {
  min-width: 200px;
  max-width: 80%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiButton-contained {
    margin: 10px 20px 0 0;
  }
}

.ImageForm__Label {
  display: flex;
  margin-right: 10px;
  border-radius: 5px;
  .MuiSvgIcon-root {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }
}

.ImageForm__DeleteButton {
  margin-left: 20px;
}