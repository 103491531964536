@import '../../common.scss';

.NoticeList {
  margin: 0 auto;
  width: 70%;

}

.LinkBox {
  padding: 12px 400px;
  height: 52px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}

.LinkBox__Link {
  font-family: 'NanumSquare Bold';
  text-decoration: none;
  color: black;
}
  
.LinkBox__Link__Select {
  font-family: 'NanumSquare Bold';
  text-decoration: none;
  color: $navbar-color;
}

.NoticeList__Header {
  display: block;
  margin: 40px auto;
  text-align: center;
}

.NoticeList__Table {
  margin: 10px auto 150px;
  height: 800px;
  width: 100%;
  border-top: 2px solid #e0e0e0;
}

.NoticeList__TableContainer {
  box-shadow: none !important;
}

.NoticeList__TableHead1 {
  font-size: 14px;
  font-family: 'NanumGothic', 'NanumSquare Regular';
  margin-left: 150px;
}

.NoticeList__TableHead2 {
  font-size: 14px;
  font-family: 'NanumGothic', 'NanumSquare Regular';
  margin-right: 30px;
}

.NoticeList__Row {
  margin: 20px 0;
  min-width: 1170px; // DO NOT FIX: this value is CALCULATED.
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NoticeList .MuiTableBody-root .MuiTableRow-root {
  &:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
  }
  height: 70px;
}

.NoticeList__Title {
  width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 21px;
  font-family: 'NanumGothic', 'NanumSquare Regular';
  font-weight: bold;
}

.NoticeList__TitleSpan {
  text-decoration: none;
  font-family: 'NanumGothic', 'NanumSquare Regular';
  font-size: 14px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

.NoticeList__Name {
  font-size: 16px;
  font-family: 'NanumGothic', 'NanumSquare Regular';
}

.NoticeList__CreateDate {
  font-size: 14px;
  color: gray;
  font-family: 'NanumGothic', 'NanumSquare Regular';
  margin-right: 15px;
}

.NoticeList__Views {
  font-size: 18px;
  font-family: 'NanumGothic', 'NanumSquare Regular';
}

.NoticeList__Table--empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoticeList__ButtonBox {
  text-align: right;
  width: 100%;
}

.MuiTableCell-root, .MuiTableCell-alignRight {
  font-family: 'NanumGothic', 'NanumSquare Regular', 'Poppins' !important;
}

.NoticeList__Pagination {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px;
}

.NoticeList__newImage {
  margin-left:10px;
  width: 1em;
}