.ApplicationMain {
    padding: 0px 10px;
    img {
      width: 100%;
    }
    .__react_modal_image__caption {
      color: black;
    }
    .__react_modal_image__modal_content img, .__react_modal_image__modal_content svg {
      width: 1172px;
      height: 879px;
    }
  }
  
  .ApplicationCagtoryName {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #2d2e2e;
  }
