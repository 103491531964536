.SearchPage {
  margin: 0 auto;
  min-height: 1400px;
  margin-bottom: 100px;
}

.SearchBar {
  padding: 70px 0;
  text-align: center;
  background-color: #f6f6f7;
  margin-bottom: 30px;
}

.SearchBar__Input {
  margin: 20px auto;
  width: 800px;
  text-align: left;
  border: 1px solid #666;
  background-color: #fff;
  display: flex;
  height: 50px;
  input {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
    font-size: large;
    padding: 10px;
  }
  .SearchBar__Input__Icon {
    padding: 10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}

.SearchPage__Result {
  padding: 0 28%;
  margin-top: 20px;
  .SearchPage__Result__Text {
    text-align: center;
  }
}

.SearchPage__Text__Color {
  color: #0f4c81;
}