.SecondNavigationBar {
  background-image: url('../static/images/nav-bg-img1.jpg');
  height: 250px;
  background-position: center;
  background-repeat:no-repeat;
  position: relative;
}

.SecondNavigationBar__BG {
  position: absolute;
  height: 250px;
  width: 100%;
  top: 0;
  display: flex;
  background-color:rgba(0, 0, 0, 0.2);
}

.SecondNavigationBar__SubTitle {
  margin: auto;
  display: block;
  color: #fafafa;
  text-align: center;
}
