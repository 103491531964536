.SearchResultList__Header {
  color: #0f4c81;
  font-family: 'Poppins Bold';
  font-size: 1.8rem;
  padding: 20px 0;
  margin-top: 50px;
}

.SearchResultList__ButtonBox {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;

}
.SearchResultList__ShowMoreButton {
  width: 350px;
}
