.PaperMain {
  display: flex;
  justify-content: center;
  text-decoration: 'none';
  margin-block: 160px;
}

.CardDiv {
  margin-inline: 80px;
}

.CardLink {
  text-decoration: none;
}

.CardPaper {
  width: 400px;
  height: 360px;
  margin: 0px;

  display: flex;
  align-items: center;
}

.CardBodyWrap {
  margin-inline: auto;
  font-family: 'NanumSquare Regular';
  margin-top: -50px;
}

.CardTitleDiv {
  margin-bottom:20px;
}

.CardTitleText {
  color: #0f4c81;
  font-family: 'Poppins Bold';
  font-size: 3rem;
}

.CardBodyText {
  span {
    font-size:1.2rem;
    color: gray;
  }
}

.DownloadPage__Admin {
  padding-top: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}